import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import * as ossStyles from "./oss.module.css";
const cx = classNames.bind(ossStyles);

const OssPage = () => {
    const [params, setParams] = useState("");

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const studio_user_id = urlParams.get("uid") ?? null;
        const prd = urlParams.get("edition") ?? null;

        const params = new URLSearchParams({
            pluginId: "org.talend.rcp",
            class: "org.talend.rcp.intro.OpenWebBrowserAction",
            type: "showUrl",
            url:
                "https://www.talend.com/free-trial/?utm_medium=web&utm_source=talend&utm_campaign=tospage&utm_content=freetrial",
            studio_user_id: studio_user_id,
            prd: prd,
        });
        const paramStr = params.toString();
        setParams(paramStr);
    }, []);

    return (
        <main className={cx("ossPage")}>
            <h2>Up level to cloud</h2>
            <div>
                <ul>
                    <li>Build Jobs in Talend Studio </li>
                    <li>Schedule, manage and monitor them in the cloud</li>
                    <li>Run data integrations locally or in the cloud</li>
                    <li>Share jobs and collaborate on data integration designs </li>
                    <li>Move jobs from dev to test to production with 1 click</li>
                </ul>
            </div>
            <div>
                <a id={cx("oss_cta")} href={`http://org.eclipse.ui.intro/runAction?${params}`} className={cx("button")}>
                    TRY NOW
                </a>
            </div>
            <div>
                <strong>Annual contract plans start as low as $1,000 per month</strong>
            </div>
        </main>
    );
};

export default OssPage;
